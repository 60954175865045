import $ from "jquery";

document.addEventListener("turbolinks:load", function () {

  $(".hidden_file").each(function() {
    $(this).prev().find(".custom-file-label").html($(this).html())
  });

  $(".custom-file-input").on("change", function () {
    var fileName = $(this).val().split("\\").pop();
    $(this).next(".custom-file-label").html(fileName);
  });
});
