import $ from "jquery";
import "select2/dist/css/select2.css";
import "select2-theme-bootstrap4/dist/select2-bootstrap.css";
import "select2/dist/js/select2.js";

document.addEventListener("turbolinks:load", function () {


  $('#batch_subscription_kinds').select2({
    theme: "bootstrap",
    placeholder: 'Escolha...',
    allowClear: true,
  });
  $('.select2-container').css('width', '100%');
});

document.addEventListener("turbolinks:before-cache", function() {
  $('#batch_subscription_kinds').select2('destroy');
});
