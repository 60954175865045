import React from "react";

const RemoveImgButton = (props) => (
  <button type="button" aria-label="Remover arquivo" title="Remover arquivo" {...props}>
    <svg aria-hidden="true" width="18" height="18" viewBox="0 0 18 18">
      <path d="M9 0C4.034 0 0 4.034 0 9s4.034 9 9 9 9-4.034 9-9-4.034-9-9-9z" />
      <path
        fill="#FFF"
        d="M13 12.222l-.778.778L9 9.778 5.778 13 5 12.222 8.222 9 5 5.778 5.778 5 9 8.222 12.222 5l.778.778L9.778 9z"
      />
    </svg>
  </button>
);

export default ({ src, onRemove }) => (
  <div className="img-thumbnail">
    <RemoveImgButton className="remove-img-button" onClick={onRemove} />
    <img src={src} className="img-fluid" />
  </div>
);
