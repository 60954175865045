// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
//require("@rails/activestorage").start()
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import 'jquery/src/jquery';
import "chartkick/chart.js"
import "src/shared/bootstrap_theme";
import "src/shared/action_text";
import "src/shared/filename_on_input";
import "src/shared/mural_post";
import "src/stylesheets/application.scss";
import "src/shared/toastr_messages"
import "src/shared/menu"
import "src/shared/currency_mask"
//import "src/shared/summernote_contracts"
import "../src/shared/news_post.js";
import "../src/shared/batch_subscriptions.js";
import "../src/shared/subscriptions.js";
import "../src/shared/menu.js";
import "../src/shared/forum.js";
import "src/shared/custom_file_field"
import "../src/shared/links_rss.js";

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
