import $ from "jquery";
import "select2/dist/css/select2.css";
import "select2-theme-bootstrap4/dist/select2-bootstrap.css";
import "select2/dist/js/select2.js";


document.addEventListener("turbolinks:load", function () {

    $("form#news-posts-form").each(function () {

        $('#news_post_category_ids').select2({
            theme: "bootstrap",
            placeholder: 'Escolha...',
            allowClear: true,
            ajax: {
                url: '/admin/categories/select2_search?kinds[news]=true',
                dataType: 'json',
            },
        });
        $('.select2-container').css('width', '100%');
    });
});

document.addEventListener("turbolinks:before-cache", function() {
  $('#news_post_category_ids').select2('destroy');
});
