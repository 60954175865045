import React from "react";
import Uppy from "@uppy/core";
import { DragDrop, ProgressBar } from "@uppy/react";
import ActiveStorageUpload from "uppy-activestorage-upload";

import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/progress-bar/dist/style.css";
import "./ImageUploader.scss";

import ImageDisplay from "./ImageDisplay";

export default class ImageUploader extends React.Component {
  constructor(props) {
    super(props);
    this.uppy = Uppy({
      autoProceed: true,
      locale: {
        strings: {
          dropHereOr: "Arraste ou %{browse}",
          browse: "escolha",
        },
      },
    });
    this.uppy.use(ActiveStorageUpload, { directUploadUrl: "/rails/active_storage/direct_uploads" });
    this.uppy.on("complete", this._handleUploadComplete);
    this.state = { current: props.initialImage };
  }

  componentWillUnmount() {
    this.uppy.close();
  }

  render() {
    return (
      <div className="avatar-uploader">
        <label className={this.props.required ? "required" : ""}>{this.props.label}</label>
        {!this.state.current && (
          <>
            <DragDrop uppy={this.uppy} height={this.props.height || 200} width={this.props.width || 200} note={this.props.placeholder} />
            <ProgressBar uppy={this.uppy} />
          </>
        )}
        {this.state.current && (
          <>
            <ImageDisplay src={this.state.current.url} onRemove={this._removeCurrentImage} />
            <input type="hidden" name={this.props.formInputName} value={this.state.current.id} />
          </>
        )}
      </div>
    );
  }

  _handleUploadComplete = (result) => {
    const id = result.successful[0].response.signed_id;

    this.setState({
      current: {
        id,
        url: `/rails/active_storage/blobs/${id}/avatar`,
      },
    });
  };

  _removeCurrentImage = () => {
    fetch(`/admin/delete_attachment/${this.state.current.id}`, { method: "DELETE" });
    this.setState({ current: undefined });
  };
}
