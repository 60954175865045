import $ from "jquery";
import "select2/dist/css/select2.css";
import "select2-theme-bootstrap4/dist/select2-bootstrap.css";
import "select2/dist/js/select2.js";


document.addEventListener("turbolinks:load", function () {
    $('#subscription_user_id').select2({
        theme: "bootstrap",
    });
    
    $('#subscription_kinds').select2({
        theme: "bootstrap",
        placeholder: 'Escolha...',
        allowClear: true,
    });

    $('.select2-container').css('width', '100%');
});

document.addEventListener("turbolinks:before-cache", function () {
    $('#subscription_kinds').select2('destroy');
    $('.modal').hide();
    $('.modal-backdrop').removeClass('modal-backdrop');
    $('.modal-open').removeClass('modal-open');
});
