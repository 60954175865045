import $ from "jquery";
import "select2/dist/css/select2.css";
import "select2-theme-bootstrap4/dist/select2-bootstrap.css";
import "select2/dist/js/select2.js";


document.addEventListener("turbolinks:load", function () {

    function muralPostchecked() {
        var kind_mural = [];
        kind_mural.push($("#mural_post_kind").val());
        return kind_mural.join(", ");
    }

    $('#mural_post_category_ids').select2({
        theme: "bootstrap",
        placeholder: 'Escolha...',
        allowClear: true,
        ajax: {
            url: '/admin/categories/select2_search?kinds[' + muralPostchecked() + ']=true',
            dataType: 'json',
        },
    });
    $('.select2-container').css('width', '100%');


    $(".mural_post_kind_mural").change(function () {
        var kind_mural = muralPostchecked();

        $("#mural_post_category_ids").empty();
        $('#mural_post_category_ids').select2({
            theme: "bootstrap",
            placeholder: 'Escolha...',
            allowClear: true,
            ajax: {
                url: '/admin/categories/select2_search?kinds[' + kind_mural + ']=true',
                dataType: 'json',
            },
        });

    });
});

document.addEventListener("turbolinks:before-cache", function () {
    $('#mural_post_category_ids').select2('destroy');
    $('.modal').hide();
    $('.modal-backdrop').removeClass('modal-backdrop');
    $('.modal-open').removeClass('modal-open');
});
