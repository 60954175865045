import $ from "jquery";
import "select2/dist/css/select2.css";
import "select2-theme-bootstrap4/dist/select2-bootstrap.css";
import "select2/dist/js/select2.js";


document.addEventListener("turbolinks:load", function () {

    $('input[type=radio][name="menu[is_father]"], input[type=radio][name="menu[kind]"]').change(function() {
        let kind = $('input[name="menu[kind]"]:checked').val();
        let is_father = $('input[name="menu[is_father]"]:checked').val();
     
     
     if (is_father === "false")  {
        $('#father-menu').show();
        console.log(kind, is_father);
         $('#menu_father_id').select2({
                theme: "bootstrap",
                placeholder: 'Escolha...',
                allowClear: true,
                ajax: {
                    url: '/admin/menus/select2_search?kind='+kind,
                    dataType: 'json',
                },
            });
      } else  {
        $('#father-menu').hide();
      }

       
    });
});

document.addEventListener("turbolinks:before-cache", function() {
  $('#menu_father_id').select2('destroy');
});
